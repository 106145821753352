import { Component, forwardRef, Input, OnDestroy, OnInit } from '@angular/core';
import { ControlValueAccessor, FormControl, NG_VALUE_ACCESSOR } from '@angular/forms';
import { debounceTime, Subscription } from 'rxjs';
import { ErrorHepler } from '../../utils/error-helper/error-helper';
import { GenerateRandom } from '../../utils/random';

@Component({
  selector: 'app-input',
  templateUrl: './input.component.html',
  styleUrl: './input.component.scss',
  providers: [{ provide: NG_VALUE_ACCESSOR, useExisting: forwardRef(() => InputComponent), multi: true }],
})
export class InputComponent implements OnInit, OnDestroy, ControlValueAccessor {
  @Input() name!: string;
  @Input() id = `innput-${GenerateRandom(10)}`;
  @Input() isRequired = false;
  @Input() type = 'text';
  @Input() label!: string;
  @Input() placeholder!: string;
  @Input() debounceTime = 0;
  @Input() mask!: string;
  @Input() customPatterns: any;

  @Input()
  set isDisabled(value: boolean) {
    if (value) {
      this.form.disable();
    } else {
      this.form.enable();
    }
  }

  @Input()
  set error(value: any) {
    this.errorMessage = '';
    this.isError = false;

    if (value) {
      this.isError = true;
      this.errorMessage = ErrorHepler.errorMessage(value);
    }
  }

  form = new FormControl();

  onChange = (value: string) => {};
  onTouched = (value: string) => {};

  subscription: Subscription = new Subscription();

  errorMessage = '';
  isError = false;

  ngOnInit(): void {
    const sb = this.form.valueChanges.pipe(debounceTime(this.debounceTime)).subscribe((val) => {
      this.onChange(this.form.value);
    });

    this.subscription.add(sb);
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  writeValue(obj: any): void {
    this.form.setValue(obj, { emitEvent: false });
  }

  setDisabledState?(isDisabled: boolean): void {}

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }
}
