<div class="datatable custom-scroll-bar relative max-h-[460px]">
  <table class="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
    <thead [ngClass]="theadClass" class="z-10 text-xs border dark:bg-gray-700 dark:text-gray-400 bg-gray-700 text-white sticky top-[-1px]">
      <tr>
        <th
          scope="col"
          class="border px-2 py-2 min-w-[120px] w-auto"
          [style.width]="column.width"
          [style.min-width]="column.width"
          [ngClass]="column.headerClass"
          *ngFor="let column of dataColumns"
        >
          <div class="flex font-medium justify-center items-center text-center">
            <span>{{ column.name }}</span>
            <button (click)="columnSort(column)" [hidden]="!column.sortable">
              <ng-container *ngTemplateOutlet="sortTemp; context: { column: column }"></ng-container>
            </button>
          </div>
        </th>
      </tr>
    </thead>
    <tbody [ngClass]="tBodyClass">
      <tr
        (click)="onRowClick(item)"
        (keyPress)="(null)"
        class="odd:bg-white odd:dark:bg-gray-900 even:bg-gray-50 even:dark:bg-gray-800 border-b dark:border-gray-700"
        *ngFor="let item of dataSource?.data"
      >
        <ng-container *ngFor="let column of dataColumns">
          <td class="border px-2 py-2" [ngClass]="column.cellClass" (click)="column.stopEvent ? $event.stopPropagation() : null">
            <ng-container
              [ngTemplateOutlet]="column.cellTemplate ? column.cellTemplate : defaultTemp"
              [ngTemplateOutletContext]="{ item: column.cellTemplate ? item : item[column.prop], prop: column.prop, type: column.type }"
            ></ng-container>
          </td>
        </ng-container>
      </tr>

      @if (dataSource && dataSource.data.length === 0) {
        <tr>
          <td colspan="99" class="border px-2 py-2">
            <div class="w-[80vw] text-center">ไม่มีข้อมูล</div>
          </td>
        </tr>
      }
    </tbody>
  </table>
</div>

<div *ngIf="!disableFooter" class="flex justify-between">
  <div class="text-gray-400 flex items-center">
    <div class="flex items-center">
      <span class="me-2"> entries per page</span>
      <app-select [options]="pageSizeList" [formControl]="formPageSize" (valueChange)="onPageSizeChange()"></app-select>
    </div>
    <span class="ms-2"> Showing {{ (page - 1) * limit + 1 }} to {{ (page - 1) * limit + limit }} of {{ total }} entries</span>
  </div>
  <div class="flex items-center text-gray-400">
    <ng-container *ngTemplateOutlet="paguiationTemp"></ng-container>
  </div>
</div>

<ng-template #defaultTemp let-item="item" let-type="type">
  @switch (type) {
    @case ("number") {
      {{ item | number: "1.2-2" }}
    }
    @case ("string") {
      {{ item }}
    }
    @case ("date") {
      {{ item | date: "dd/MM/yyyy" }}
    }
    @case ("dateTime") {
      {{ item | date: "dd/MM/yyyy HH:MM:SS" }}
    }
    @default {
      {{ item }}
    }
  }
</ng-template>

<ng-template #sortTemp let-column="column">
  <span *ngIf="!column.sorts">
    <svg class="w-3 h-3 ms-1.5" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 24 24">
      <path
        d="M8.574 11.024h6.852a2.075 2.075 0 0 0 1.847-1.086 1.9 1.9 0 0 0-.11-1.986L13.736 2.9a2.122 2.122 0 0 0-3.472 0L6.837 7.952a1.9 1.9 0 0 0-.11 1.986 2.074 2.074 0 0 0 1.847 1.086Zm6.852 1.952H8.574a2.072 2.072 0 0 0-1.847 1.087 1.9 1.9 0 0 0 .11 1.985l3.426 5.05a2.123 2.123 0 0 0 3.472 0l3.427-5.05a1.9 1.9 0 0 0 .11-1.985 2.074 2.074 0 0 0-1.846-1.087Z"
      />
    </svg>
  </span>
  <span *ngIf="column.sorts === 'asc'">
    <svg
      class="w-3 h-3 ms-1.5"
      aria-hidden="true"
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      fill="currentColor"
      viewBox="0 0 24 24"
    >
      <path
        fill-rule="evenodd"
        d="M18.425 10.271C19.499 8.967 18.57 7 16.88 7H7.12c-1.69 0-2.618 1.967-1.544 3.271l4.881 5.927a2 2 0 0 0 3.088 0l4.88-5.927Z"
        clip-rule="evenodd"
      />
    </svg>
  </span>
  <span *ngIf="column.sorts === 'desc'">
    <svg
      class="w-3 h-3 ms-1.5"
      aria-hidden="true"
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      fill="currentColor"
      viewBox="0 0 24 24"
    >
      <path
        fill-rule="evenodd"
        d="M5.575 13.729C4.501 15.033 5.43 17 7.12 17h9.762c1.69 0 2.618-1.967 1.544-3.271l-4.881-5.927a2 2 0 0 0-3.088 0l-4.88 5.927Z"
        clip-rule="evenodd"
      />
    </svg>
  </span>
</ng-template>

<ng-template #paguiationTemp>
  <nav aria-label="Page navigation example">
    <ul class="flex items-center -space-x-px h-8 text-sm">
      <li>
        <button
          (click)="pagePreviousToFirst()"
          [disabled]="page === 1"
          class="flex items-center justify-center px-2 h-8 ms-0 leading-tight text-gray-500 bg-white border border-e-0 border-gray-300 rounded-s-lg hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white"
          [ngClass]="{ disabled: page === 1 }"
        >
          <span class="sr-only">Previous</span>
          <svg class="w-5 h-5" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24">
            <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m17 16-4-4 4-4m-6 8-4-4 4-4" />
          </svg>
        </button>
      </li>
      <li>
        <button
          (click)="pagePrevious()"
          [disabled]="page === 1"
          [ngClass]="{ disabled: page === 1 }"
          class="flex items-center justify-center px-3 h-8 ms-0 leading-tight text-gray-500 bg-white border border-e-0 border-gray-300 hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white"
        >
          <span class="sr-only">Previous</span>
          <svg class="w-2.5 h-2.5 rtl:rotate-180" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 6 10">
            <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 1 1 5l4 4" />
          </svg>
        </button>
      </li>
      <li *ngFor="let pageItem of pageList">
        <button
          (click)="onPageSelected(pageItem)"
          class="flex items-center justify-center px-3 h-8 leading-tight text-gray-500 bg-white border border-gray-300 hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white"
          [ngClass]="{ '!bg-gray-200': pageItem === page }"
        >
          {{ pageItem }}
        </button>
      </li>
      <li>
        <button
          (click)="pageNext()"
          [disabled]="page === pageTotal"
          [ngClass]="{ disabled: page === pageTotal }"
          class="flex items-center justify-center px-3 h-8 leading-tight text-gray-500 bg-white border border-gray-300 hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white"
        >
          <span class="sr-only">Next</span>
          <svg class="w-2.5 h-2.5 rtl:rotate-180" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 6 10">
            <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m1 9 4-4-4-4" />
          </svg>
        </button>
      </li>
      <li>
        <button
          (click)="pageNextToTotal()"
          [disabled]="page === pageTotal"
          [ngClass]="{ disabled: page === pageTotal }"
          class="flex items-center justify-center px-1 h-8 leading-tight text-gray-500 bg-white border border-gray-300 rounded-e-lg hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white"
        >
          <span class="sr-only">Next</span>
          <svg class="w-5 h-5" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24">
            <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m7 16 4-4-4-4m6 8 4-4-4-4" />
          </svg>
        </button>
      </li>
    </ul>
  </nav>
</ng-template>
