import { ValidationErrors } from '@angular/forms';

export class ErrorMessage {
  static getErrorMessage(error: ValidationErrors | any): string {
    const errorKey = Object.keys(error)[0];
    switch (errorKey) {
      case 'minlength':
        return 'จำนวนตัวอักษรขั้นต่ำ ' + error.minlength.requiredLength + ' ตัวอักษร';
      case 'maxlength':
        return 'จำนวนตัวอักษรไม่เกิน ' + error.maxlength.requiredLength + ' ตัวอักษร';
      case 'min':
        return 'จำนวนต้องไม่น้อยกว่า ' + Number(error.min.min).toLocaleString('en-US');
      case 'max':
        return 'จำนวนต้องไม่เกิน ' + Number(error.max.max).toLocaleString('en-US');
      case 'min_fin_amount':
        return 'วงเงินจัดสินเชื่อต้องไม่ต่ำกว่า ' + Number(error.min_fin_amount.amount).toLocaleString('en-US') + ' บาท';
      case 'max_fin_amount':
        return 'วงเงินจัดสินเชื่อต้องไม่เกิน ' + Number(error.max_fin_amount.amount).toLocaleString('en-US') + ' บาท';
      case 'min_percent_down_amount':
        return 'เปอร์เซ็นต์เงินดาวน์ต้องไม่ต่ำกว่า ' + Number(error.min_percent_down_amount.amount).toLocaleString('en-US') + ' %';
      case 'max_percent_down_amount':
        return 'เปอร์เซ็นต์เงินดาวน์ต้องไม่เกิน ' + Number(error.max_percent_down_amount.amount).toLocaleString('en-US') + ' %';
      case 'required':
        return 'กรุณากรอกข้อมูล';
      case 'duplicate':
        return 'VALIDATION.DUPLICATE';
      case 'email':
        return 'VALIDATION.EMAIL';
      case 'mustMatch':
        return 'รหัสผ่านและยืนยันรหัสผ่านไม่ตรงกัน';
      case 'thaiNationalID':
        return 'VALIDATOR.THAI_NATION_ID';
      case 'utmSpecialInvalid':
        return 'รูปแบบ UTM ไม่ถุกต้อง';
      case 'phoneInvalid':
        return 'รูปแบบเบอร์โทรไม่ถูกต้อง';
      case 'hasCapitalCase':
      case 'has_upper_case':
      case 'hasSmallCase':
      case 'has_lower_case':
      case 'hasNumber':
      case 'has_number':
      case 'hasSpecialCharacters':
      case 'has_special_characters':
        return 'รูปแบบรหัสผ่านไม่ถูกต้อง';
      default:
        return this.getServerErrorMessage(error);
    }
  }

  static getServerErrorMessage(value: any): string {
    if (Array.isArray(value)) {
      const error = this.getErrorMessage(value[0]);
      if (!Array.isArray(error)) {
        return error;
      } else {
        return this.getServerErrorMessage(error);
      }
    } else {
      return `${value}`;
    }
  }
}
