<div class="p-5 md:p-5 text-center">
  <h3 class="text-xl font-normal text-gray-900 dark:text-gray-400">
    {{ progress < 100 ? 'กำลังดำเนินการ' : 'สำเร็จ' }}
  </h3>

  <div class="mt-2">
  <div class="progress-container w-full bg-gray-200 rounded-full h-2.5 dark:bg-gray-700">
      <div
        class="progress-bar bg-blue-500 h-2.5 rounded-full"
        [style.width.%]="progress"
      ></div>
    </div>
    <p class="mt-2 text-sm text-gray-500 text-center">{{ progress }}%</p>
</div>
