<div class="mb-2">
  <label [for]="id" class="block mb-2 text-sm font-medium text-[#797979] dark:text-white">
    {{ label }}
    <span *ngIf="isRequired" class="text-red-600 dark:text-red-500">*</span>
  </label>
  <input
    [type]="type"
    [id]="id"
    [ngClass]="isError ? 'form-error' : 'form-info'"
    [placeholder]="placeholder"
    [formControl]="form"
    autocomplete="off"
    [mask]="mask"
    [patterns]="customPatterns"
    [dropSpecialCharacters]="false"
  />
  <p class="mt-1 text-sm text-red-600 dark:text-red-500">{{ errorMessage }}</p>
</div>
