import { AfterViewInit, Component, EventEmitter, forwardRef, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { ControlValueAccessor, FormControl, NG_VALUE_ACCESSOR } from '@angular/forms';
import { Datepicker } from 'flowbite';
import type { DatepickerOptions } from 'flowbite';
import type { InstanceOptions } from 'flowbite';
import moment from 'moment';
import { ErrorHepler } from '../../utils/error-helper/error-helper';
import { GenerateRandom } from '../../utils/random';

@Component({
  selector: 'app-datepicker',
  templateUrl: './datepicker.component.html',
  styleUrl: './datepicker.component.scss',
  providers: [{ provide: NG_VALUE_ACCESSOR, useExisting: forwardRef(() => DatepickerComponent), multi: true }],
})
export class DatepickerComponent implements AfterViewInit, OnInit, OnDestroy, ControlValueAccessor {
  options: DatepickerOptions = {
    defaultDatepickerId: null,
    autohide: false,
    format: 'dd/mm/yyyy',
    maxDate: null,
    minDate: null,
    orientation: 'bottom',
    buttons: false,
    autoSelectToday: 1,
    title: null,
    rangePicker: false,
    onShow: () => {},
    onHide: () => {},
  };

  @Input() name!: string;
  @Input() id: string = GenerateRandom(10);

  @Input() isRequired = false;
  @Input() type = 'text';
  @Input() label!: string;
  @Input() placeholder = '';
  @Input() format = 'YYYY-MM-DD';
  @Output() changed = new EventEmitter();

  @Input()
  set error(value: any) {
    this.errorMessage = '';
    this.isError = false;

    if (value) {
      this.isError = true;
      this.errorMessage = ErrorHepler.errorMessage(value);
    }
  }

  form = new FormControl();

  onChange = (value: string | null) => {};
  onTouched = (value: string) => {};

  errorMessage = '';
  isError = false;

  $datepickerEl!: HTMLInputElement;
  datepicker!: Datepicker;

  ngOnInit(): void {}

  ngAfterViewInit(): void {
    this.initialDatepicker();
  }

  initialDatepicker() {
    /*
     * $datepickerEl: required
     * options: optional
     * instanceOptions: optional
     */
    this.$datepickerEl = document.getElementById(`datepicker-${this.id}`) as HTMLInputElement;
    const instanceOptions: InstanceOptions = {
      id: `datepicker-custom-${this.id}`,
      override: true,
    };
    this.datepicker = new Datepicker(this.$datepickerEl, this.options, instanceOptions);
    // this.datepicker.setDate();
    this.$datepickerEl.addEventListener('changeDate', () => {
      this.onChangeValue();
    });
  }

  ngOnDestroy(): void {
    this.$datepickerEl.removeEventListener(
      'changeDate',
      () => {
        this.onChangeValue();
      },
      true,
    );
  }

  writeValue(obj: any): void {
    if (obj) {
      setTimeout(() => {
        const value = moment(obj).format('DD/MM/YYYY');
        this.datepicker.setDate(value);
      });
    }
  }

  setDisabledState?(isDisabled: boolean): void {}

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  onChangeValue() {
    let value;
    if (this.datepicker.getDate()) {
      value = moment(this.datepicker.getDate()).format(this.format || 'YYYY-MM-DD');
    } else {
      value = '';
    }

    this.datepicker.hide();

    this.onChange(value);
    this.changed.emit(value);
  }
}
