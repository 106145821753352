import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { InputComponent } from './input/input.component';
import { ReactiveFormsModule } from '@angular/forms';
import { SelectComponent } from './select/select.component';
import { DatepickerComponent } from './datepicker/datepicker.component';
import { DatatableComponent } from './datatable/datatable.component';
import { CheckboxComponent } from './checkbox/checkbox.component';
import { SelectSearchComponent } from './select-search/select-search.component';
import { ProgressBarComponent } from './progress-bar/progress-bar.component';
import { provideNgxMask, NgxMaskDirective, NgxMaskPipe } from 'ngx-mask';

@NgModule({
  declarations: [
    InputComponent,
    SelectComponent,
    DatepickerComponent,
    DatatableComponent,
    CheckboxComponent,
    SelectSearchComponent,
    ProgressBarComponent,
  ],
  imports: [ReactiveFormsModule, CommonModule, NgxMaskDirective],
  exports: [
    InputComponent,
    SelectComponent,
    DatepickerComponent,
    DatatableComponent,
    CheckboxComponent,
    SelectSearchComponent,
    ProgressBarComponent,
  ],
  providers: [provideNgxMask()],
})
export class CoreComponentsModule {}
