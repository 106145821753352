<div class="flex items-center">
  <input

    id="default-checkbox"
    type="checkbox"
    value=""
    [checked]="checked"
    (change)="toggleChecked()"
    class="w-4 h-4 text-blue-600 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
  />
  <label *ngIf="label" for="default-checkbox" class="ms-2 text-sm font-medium text-gray-900 dark:text-gray-300">{{ label }}</label>
</div>
